import React from "react"
import Layout from "../../components/layout"
//import styles from "../components/kontakt.module.css"
export default () => (
  <Layout>
    <h2>Psychoterapia dzieci i młodzieży</h2>
    <p>
      Psychoterapia indywidualna dla dzieci, szczególnie tych młodszych
      przypomina często swobodną, niekierowaną zabawę opartą na odwoływaniu się
      do naturalnych zainteresowań dziecka. Podczas terapii, dziecko
      wykorzystując swoją naturalną aktywność przekazuje ważne dla niego sprawy
      i problemy, pokazując jednocześnie, w jaki sposób je przeżywa. W relacji
      terapeutycznej pomiędzy dzieckiem a terapeutą możliwa staje się zmiana
      przeżywanych przez dziecko trudności. Pracując z dzieckiem, jednocześnie
      zapraszam do współpracy jego rodzinę, tak aby przy zachowaniu tajemnicy
      zawodowej wymieniać się spostrzeżeniami, móc odpowiedzieć na pojawiające
      się pytania rodziców/opiekunów oraz zastanowić się z jakich zasobów
      rodzina może skorzystać, aby wspierać dziecko.
    </p>
    <p>
      Psychoterapia indywidualna dla młodzieży to spotkania, podczas których za
      pomocą rozmowy, różnych zadań i doświadczeń stwarza się bezpieczną
      przestrzeń, w której młoda osoba ma możliwość opowiedzenia m.in. o swoich
      trudnościach, o swoich potrzebach, o zmianie jakiej oczekuje.
    </p>
    <p>Zapraszam na spotkanie, jeśli dziecko lub młoda osoba:</p>
    <ul>
      <li>chce bardziej zrozumieć siebie, poznać, odkryć swoje mocne strony</li>
      <li>
        doświadcza różnego rodzaju trudności w nawiązywaniu i utrzymaniu relacji
        z innymi ludźmi
      </li>
      <li>
        doświadcza długotrwałego smutku, zmęczenia, rozdrażnienia, złości,
        zniechęcenia, braku motywacji, poczucia winy
      </li>
      <li>przejawia zachowania konfliktowe, agresywne, autodestruktywne</li>
      <li>
        zmaga się z różnego rodzaju lękiem, z zaburzeniami nastroju (np. z
        depresją), z zaburzeniami odżywiania
      </li>
      <li>
        przejawia trudności związane z niską samooceną, z poczuciem bycia
        nieakceptowanym, z wyobcowaniem
      </li>
      <li>
        doświadczyło straty, kryzysu, traumy (np. śmierć bliskiej osoby,
        przemoc)
      </li>
      <li>
        poświęca zbyt dużo czasu/ uwagi na liczeniu kalorii, kontroli tego co
        je; jest niezadowolone ze swojej wagi
      </li>
      <li>doświadcza innego rodzaju trudności emocjonalnych/społecznych</li>
    </ul>
    <p>Zapraszam rodziców: </p>
    <ul>
      <li>których niepokoi rozwój ich dziecka</li>
      <li>pragną zrozumieć zachowanie swojego dziecka</li>
      <li>chcą się lepiej komunikować ze swoim dzieckiem</li>
      <li>
        chcą uzyskać wsparcie w trudnościach wychowawczych lub skonsultować
        stosowane dotychczas metody wychowawcze
      </li>
      <li>
        którzy chcą się dowiedzieć, w jaki jeszcze sposób mogą wspierać swoje
        dziecko
      </li>
    </ul>

    <p>
      Na pierwszą konsultację dotyczącą dzieci oraz młodzieży zapraszam samych
      rodziców/rodzica/opiekunów prawnych.
    </p>
    
    <p>
      Po ukończeniu 18-tego roku życia, na terapię młoda osoba może zgłosić się
      samodzielnie.
    </p>
    <p>
      Na początku odbywa się kilka (1-3) spotkań konsultacyjnych, po których
      wspólnie ustalimy, czy i jaka będzie forma naszej dalszej współpracy ( np.
      jednorazowa konsultacja, konsultacje dla rodziców, terapia indywidualna,
      spotkania rodzinne).
    </p>
    <hr />
    <h2>Cennik</h2>
    <p>
      Terapia, konsultacja trwa 50 min. (spotkania indywidualne) lub 60 min. (
      spotkania rodzinne).
    </p>
    <ul>
      <li>konsultacja psychologiczna – 180 zł </li>
      <li>terapia indywidualna – 180 zł </li>
      <li>terapia rodzinna – 180 zł </li>
    </ul>
  </Layout>
)
